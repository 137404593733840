import React from "react";
import {Route, Routes} from "react-router-dom";
import {RoutingDefinition} from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as Pages from "./pages";

const mainPage = {route: "/", name: "Strona główna"};
const users = {route: "/users", name: "Użytkownicy"}
const events = {route: "/events", name: "Wydarzenia"}

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    login: {route: "/login"},
    activate: {route: "/activate"},
    forgotPassword: {route: "/forgotPassword"},
    forgotPasswordComplete: {route: "/forgotPasswordComplete"},

    users: {route: "/users", breadcrumbEntries: [mainPage, users]},
    contactFormRecipients: {
        route: "/contactFormRecipients",
        breadcrumbEntries: [mainPage, {name: "Formularz kontaktowy"}]
    },
    events: {route: "/events", breadcrumbEntries: [mainPage, events]},
    eventPhotos: {route: "/event/:eventId", breadcrumbEntries: [mainPage, events, {name: "Zdjęcia wydarzenia"}]},
    analytics: {route: "/analytics", breadcrumbEntries: [mainPage, {name: "Statystyki"}]}
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>;

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.login.route} element={<Pages.LoginPage/>}/>
        <Route path={RoutingPaths.users.route} element={<Pages.UsersPage/>}/>

        <Route path={RoutingPaths.forgotPassword.route} element={<Pages.ForgotPasswordPage/>}/>
        <Route path={RoutingPaths.forgotPasswordComplete.route} element={<Pages.ForgotPasswordCompletePage/>}/>
        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage/>}/>
        <Route path={RoutingPaths.contactFormRecipients.route} element={<Pages.ContactFormRecipientsPage/>}/>
        <Route path={RoutingPaths.events.route} element={<Pages.EventsPage/>}/>
        <Route path={RoutingPaths.eventPhotos.route} element={<Pages.EventPhotosPage/>}/>
        <Route path={RoutingPaths.analytics.route} element={<Pages.AnalyticsPage/>}/>

        <Route path={"/"} element={<Pages.MainPage/>}/>
    </Routes>
}
