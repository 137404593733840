import React from "react";
import {loadingSpin} from "./routingContainer";
import {AuthInitialized, Protected} from "./routingWrappers";

const Main = React.lazy(() => import("../components/mainPage"));
const Login = React.lazy(() => import( "../components/loginPage"));
const Users = React.lazy(() => import("../applicationUser/applicationUserListContainer"));
const ContactFormRecipients = React.lazy(() => import("../contactFormRecipient/contactFormRecipientListContainer"));
const Events = React.lazy(() => import("../event/eventListContainer"));
const EventPhotos = React.lazy(() => import("../eventPhotos/eventPhotosListContainer"));
const Analytics = React.lazy(() => import("../analyticsEvent/analyticsEventListContainer"));

const ForgotPassword = React.lazy(() => import("../components/forgotPassword"));
const ForgotPasswordComplete = React.lazy(() => import("../components/forgotPasswordComplete"))
const Activate = React.lazy(() => import("../authentication/activate/activatePage"));

export const LoginPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Login/>
</AuthInitialized>;

export const ForgotPasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPassword/>
</AuthInitialized>;

export const ForgotPasswordCompletePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPasswordComplete/>
</AuthInitialized>

export const ActivatePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Activate/>
</AuthInitialized>;

export const UsersPage = () => <Protected loadingIndicator={loadingSpin}>
    <Users/>
</Protected>

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main/>
</Protected>

export const ContactFormRecipientsPage = () => <Protected loadingIndicator={loadingSpin}>
    <ContactFormRecipients/>
</Protected>

export const EventsPage = () => <Protected loadingIndicator={loadingSpin}>
    <Events/>
</Protected>

export const EventPhotosPage = () => <Protected loadingIndicator={loadingSpin}>
    <EventPhotos/>
</Protected>

export const AnalyticsPage = () => <Protected loadingIndicator={loadingSpin}>
    <Analytics/>
</Protected>
