import {BarChartOutlined, CalendarOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import styles from "./siderMenu.module.less"
import {useCurrentRoutingPath} from "digimuth-components/dist";

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

    return <>
        <NavLink to={"/"}>
            <h3 className={styles.logo}>CMS - Dolina Krzny</h3>
        </NavLink>
        <Menu theme="dark" selectedKeys={[route]} mode="inline">
            <Menu.Item key={RoutingPaths.users.route} icon={<UserOutlined/>}>
                <NavLink to={RoutingPaths.users.route}>
                    Użytkownicy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.contactFormRecipients.route} icon={<MailOutlined/>}>
                <NavLink to={RoutingPaths.contactFormRecipients.route}>
                    Formularz kontaktowy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.events.route} icon={<CalendarOutlined/>}>
                <NavLink to={RoutingPaths.events.route}>
                    Wydarzenia
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.analytics.route} icon={<BarChartOutlined />}>
                <NavLink to={RoutingPaths.analytics.route}>
                    Statystyki
                </NavLink>
            </Menu.Item>
        </Menu>
    </>;
}
