import './App.less';
import {Provider as InversifyProvider} from 'inversify-react';
import {Provider} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, {RoutingPaths} from "./pages/routing/routingContainer";
import React from "react";
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import ApiService from "./services/apiService";
import SiderMenu from './pages/components/siderMenu/siderMenu';
import HeaderContainer from './pages/components/header/headerContainer';
import FooterContainer from './pages/components/footer/footerContainer';
import {CrudBreadcrumb, CrudLayout} from 'digimuth-components/dist';
import {Container} from 'inversify';
import {AuthProvider, AuthProviderProps, initUserManager, useAuth, UserManager} from 'oidc-react';
import {createBrowserHistory} from "history";
import {ConfigProvider} from "antd";
import plPL from 'antd/lib/locale/pl_PL';

const authSettings: AuthProviderProps = {
    authority: process.env.REACT_APP_URL,
    clientId: "Digimuth.BialaPodlaska.Audiotour.Web",
    redirectUri: process.env.REACT_APP_URL,
    autoSignIn: false,
    automaticSilentRenew: true,
    scope: "openid Digimuth.BialaPodlaska.Audiotour.WebAPI profile offline_access"
};

export const userManager: UserManager | undefined = initUserManager(authSettings);

function App() {

    const container = new Container();
    const history = createBrowserHistory();
    container.bind(ApiService).toSelf().inSingletonScope();

    return (
        /*
        // @ts-ignore */
        <AuthProvider
            userManager={userManager}
            onSignIn={u => {
                const fromState = u?.state?.targetPath;
                const targetUrl = fromState ? fromState : "/";
                history.replace(targetUrl);
            }}
            autoSignIn={false}>
            <ConfigProvider locale={plPL}>
                <Provider store={store}>
                    <InversifyProvider container={container}>
                        <HistoryRouter history={history}>
                            <Layout/>
                        </HistoryRouter>
                    </InversifyProvider>
                </Provider>
            </ConfigProvider>
        </AuthProvider>
    )
}

function Layout() {
    const auth = useAuth();

    return <CrudLayout
        breadcrumb={<CrudBreadcrumb routingDefinitions={RoutingPaths}/>}
        siderMenu={<SiderMenu/>}
        header={<HeaderContainer/>}
        footerContent={<FooterContainer/>}
        showSider={!!auth.userData}
    >
        <RoutingContainer/>
    </CrudLayout>;
}

export default App;
